import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import * as React from "react"
import { useEffect, useState } from "react"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import api from "../../../../services"
import * as S from "./style"

const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `40%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
}

export default function ModalCheck({ modalIsOpen, setModalIsOpen, data }) {
  const dataObj = new Date(data.expires_at)
  const ano = dataObj.getFullYear()
  const mes = String(dataObj.getMonth() + 1).padStart(2, "0")
  const dia = String(dataObj.getDate()).padStart(2, "0")
  const newDate = `${ano}-${mes}-${dia}`

  const handleClose = () => setModalIsOpen(false)
  const [date, setDate] = useState()
  const [checkedPro, setCheckedPro] = useState(false)
  const [checkedFree, setCheckedFree] = useState(false)
  const [loading, setLoading] = useState(false)

  const MySwal = withReactContent(Swal)

  const handleChangeFree = (event) => {
    setCheckedFree(event.target.checked)
    setCheckedPro(!event.target.checked)
  }

  const handleChangePro = (event) => {
    setCheckedPro(event.target.checked)
    setCheckedFree(!event.target.checked)
  }

  async function sendData() {
    try {
      setLoading(true)
      const res = await api.put("/api/admin/users/plan", {
        user_id: data.id,
        plan_id: checkedPro ? 2 : 1,
        expires_at: checkedPro ? date : "",
      })
      setTimeout(() => {
        window.location.href = `/home`
      }, [1000])
      setLoading(false)
    } catch (err) {
      console.log(err)
      MySwal.fire({
        icon: "error",
        title: "Erro!",
        text: "Tente novamente...",
      })
      setTimeout(() => {
        window.location.href = `/home`
      }, [1000])
      setLoading(false)
    }
  }

  useEffect(() => {
    if (data.plan_id === 2) {
      setCheckedPro(true)
      setDate(data.expiracao_plano)
    } else {
      setCheckedFree(true)
    }
  }, [])

  return (
    <Modal
      open={modalIsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <S.ContainerTitle>
            <S.Text>Atualizar plano do usuário</S.Text>
            <FormGroup>
              <S.DateWrapper>
                <FormControlLabel
                  control={
                    <Checkbox checked={checkedPro} onChange={handleChangePro} />
                  }
                  label="Usuário PRO"
                />
                <S.SubtitleWrapper>
                  <S.Subtitle>Data de expiração</S.Subtitle>
                  <TextField
                    id="date"
                    type="date"
                    value={date ? date : newDate}
                    inputProps={{
                      style: {
                        padding: 10,
                        height: 20,
                        marginRight: 10,
                      },
                    }}
                    // label="Data de expiração"
                    onChange={(e) => setDate(e.target.value)}
                  />
                </S.SubtitleWrapper>
              </S.DateWrapper>
              <FormControlLabel
                control={
                  <Checkbox checked={checkedFree} onChange={handleChangeFree} />
                }
                label="Usuário FREE"
              />
            </FormGroup>
            <S.BottomInfo>
              <S.BottomButtons>
                <S.OutlineButton onClick={sendData}>
                  <p>Salvar</p>
                </S.OutlineButton>
                <S.DefaultButton onClick={handleClose}>
                  <p>Cancelar</p>
                </S.DefaultButton>
              </S.BottomButtons>
            </S.BottomInfo>
          </S.ContainerTitle>
        </Typography>
      </Box>
    </Modal>
  )
}
