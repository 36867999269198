import styled from "styled-components";

export const Header = styled.header`
  margin: 15px 0 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const HeaderButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 27px 0 10px 0;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Select = styled.select`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;
  margin-right: 20px;

  :focus-visible {
    outline: 1px solid #ffffff00;
  }
`;
export const Input = styled.input`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  padding: 10px;

  :focus {
    outline: none;
  }
`;

export const Button = styled.button`
  display: block;
  width: 159px;
  height: 40px;
  margin-left: 20px;
  margin-top: 2px;
  background-color: #919DF2;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border-radius: 16px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out;

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;

export const ButtonHeader = styled.button`
  background-color: #01b797;
  border: none;
  border-radius: 10px;
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  margin: 10px;
`;

export const ImgCloud = styled.img`
  padding-right: 5px;
`;

export const TimeFilter = styled.div`
  margin-right: 20px;
`;

export const TextIcon = styled.span`
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: ${(props) =>
    props.status === "aprovado"
      ? "#28c76f"
      : props.status === "reprovado"
        ? "#E93E3E"
        : props.status === "estornado"
          ? "#E93E3E"
          : props.status === "cancelado"
            ? "#333333"
            : "#FAAB36"};
`;

export const Icon = styled.div`
  width: 120px;
  height: 20px;
  border-radius: 17px;
  padding: 1px 9px;
  background-color: ${(props) =>
    props.status === "aprovado"
      ? "#28c76f20"
      : props.status === "reprovado"
        ? "#E93E3E20"
        : props.status === "estornado"
          ? "#E93E3E20"
          : props.status === "cancelado"
            ? "#33333320"
            : "#FAAB3620"};
  display: flex;
  align-items: center;
  justify-content: center;
`;
