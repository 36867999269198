import styled from "styled-components";

export default styled.input`
  margin-bottom: 17px;
  width: ${({ width = '100%' }) => width };
  height: ${({ height = '38px' }) => height };
  border-radius: 5px;
  border: 1px solid #D9D9D9;
  padding: 4px;

  :focus-visible {
    outline: 1px solid #D9D9D9;
  }
`