import styled from "styled-components";

export const Icon = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
`;

export const Text = styled.span`
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 23px;
  line-height: 10px;
  color: #000000;
`;

export const ContainerTitle = styled.div`
  /* margin-bottom: 20px; */
  align-content: center;
`;

export const DateWrapper = styled.div`
    display: flex;
    margin-top: 40px;
    align-items: flex-end;
    
`

export const Subtitle = styled.span`
  font-size: 13px;
  line-height: 10px;
  color: #000000;
    margin-bottom: 5px;

`

export const SubtitleWrapper = styled.div`
        display: flex;
        flex-direction: column;
`


export const DivRow = styled.div`
  margin: 12px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BottomInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 30px 5px 0px;
`;
export const BottomButtons = styled.div`
  display: flex;
`;
export const TextSecondary = styled.div`
  text-align: center;
  font-weight: bold;
  font-size: 24px;
`;

export const OutlineButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  margin: 15px 10px;
  background-color: #fff;
  color: #919DF2;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  border: 2px solid #919DF2;
  cursor: pointer;
  transition: all 0.3s ease-out;

  p {
    margin: 10px 5px;
  }

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;
export const DefaultButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 50px;
  margin: 15px 10px;
  background-color: #919DF2;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  border-radius: 10px;
  border: 2px solid #919DF2;
  cursor: pointer;
  transition: all 0.3s ease-out;

  p {
    margin: 10px 5px;
  }

  :hover {
    opacity: 0.9;
    transition: opacity 0.3s;
  }
`;
