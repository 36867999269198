import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: localStorage.getItem("token")
      ? "Bearer " + localStorage.getItem("token")
      : "",
    Accept: "application/json",
    ContentType: "application/json",
  },
});


api.interceptors.response.use((response) => {
  if (response.message) {
    throw response;
  } else {
    return response;
  }
});

export default api;
